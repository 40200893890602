import { hexToRgb } from '@mui/material';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router';

import { ViewType } from './modules/AdminTool/types';
import { UserDetails } from './modules/Authentication/reducers/authentication';

export const defaultPageSize = 20;

// Custom hooks for debounce
export const useDebounce = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const formateValue = (value: number | string) => {
  if (value) {
    const formattedValue = numeral(value).format('0,0.[000]a');
    return formattedValue.toUpperCase();
  } else {
    return 'NA';
  }
};
export const signalPerPage = 10;

export const projectStatus = {
  active: 'activated',
  deactive: 'deactivated',
  enabled: 'enabled',
  disabled: 'disabled',
};

/**
 * Hooks to get the url query params
 */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/**
 * To encode & decode data using base64
 * @param data
 */
export const base64Encode = (data: any) => btoa(JSON.stringify(data));
export const base64Decode = <T>(encoded: string): T => JSON.parse(atob(encoded));

/**
 * To clear service worker cache
 */
export const clearSwCache = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
};

/**
 * To get the aspect type from aspect name eg. Value Proposition => value_proposition
 * @param type name of the aspect
 */
export const getAspectType = (type: string) => {
  if (type && type.split(' ').length > 1) {
    return type.toLowerCase().replace(' ', '_');
  } else if (type && type.split(' ').length === 1) {
    return type.toLowerCase();
  } else {
    return type;
  }
};

export const signalFormatedValue = (value: any) => {
  const formattedValue = numeral(value).format('0,000,000');
  return formattedValue;
};

export const convertStringToHTML = (text: string) => {
  return ReactHtmlParser(text, {
    transform: (node) => {
      return node.type === 'tag' && node.name === 'script' ? null : undefined;
    },
  });
};

export function isArrayOfStrings(arr: any[]): arr is string[] {
  return arr.every((item) => typeof item === 'string');
}

function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const addHighlight = (originalString: string, searchString: string) => {
  const searchWords = searchString.split(/\s+/);

  const patterns = searchWords.map((word) => new RegExp(`(${escapeRegExp(word)})`, 'gi'));

  const highlightedString = originalString.replace(
    new RegExp(patterns.map((pattern) => pattern.source).join('|'), 'gi'),
    (match) => `<mark>${match}</mark>`
  );

  return highlightedString;
};

export const capitalizeString = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const capitalizeSentence = (text: string) => text.split('_').map(capitalizeString).join(' ');

export const getTheme = (user?: UserDetails) => (user ? user.appearanceColor ?? 'DARK_BLUE' : 'DARK_BLUE');

export const getViewType = (user?: UserDetails) =>
  user?.appearanceViewType ? (user?.appearanceViewType as ViewType) : undefined;

export const shuffleArray = <T>(array: Array<T>) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

export const setAlphaToRgb = (hex: string, opacityPercentage: number) => {
  if (opacityPercentage >= 1 || opacityPercentage < 0) return hex;

  const rgb = hexToRgb(hex);

  const insertIndex = rgb.indexOf(')');

  return `${rgb.slice(0, insertIndex)},${opacityPercentage})`;
};
