import { SET_ASPECTS, SET_SELECTED_ASPECT_PAIR } from '../../Aspects/types';
import { EMPTY_ASPECT_PAIR } from '../../Aspects/utils';
import { SET_ASPECT_NOTES } from '../types/aspects';
import { ModelState } from '../types/model';
import { SET_SELECTED_ASPECT_NOTE } from '../types/note';

interface AspectsAction {
  type: typeof SET_ASPECTS;
  data?: ModelState['aspects'];
}

interface AspectsNotesAction {
  type: typeof SET_ASPECT_NOTES;
  data?: ModelState['aspectNotes'][0];
}

interface SelectedNotesAction {
  type: typeof SET_SELECTED_ASPECT_NOTE;
  data?: ModelState['selectedNote'];
}

interface SelectedAspectPairAction {
  type: typeof SET_SELECTED_ASPECT_PAIR;
  data?: ModelState['selectedAspectPair'];
}

type ModelAction = AspectsAction | SelectedNotesAction | AspectsNotesAction | SelectedAspectPairAction;

const initialState: ModelState = {
  isFetching: false,
  aspects: undefined,
  aspectNotes: [],
  selectedAspectPair: EMPTY_ASPECT_PAIR,
};

const Model = (state = initialState, action: ModelAction): ModelState => {
  switch (action.type) {
    case SET_ASPECTS:
      return {
        ...state,
        aspects: action.data,
      };
    case SET_ASPECT_NOTES:
      return {
        ...state,
        aspectNotes: [...(state.aspectNotes ?? []), ...(action.data ? [action.data] : [])],
      };
    case SET_SELECTED_ASPECT_NOTE:
      return {
        ...state,
        selectedNote: action.data,
      };
    case SET_SELECTED_ASPECT_PAIR:
      return { ...state, selectedAspectPair: action.data ?? EMPTY_ASPECT_PAIR };
    default:
      return state;
  }
};

export default Model;
