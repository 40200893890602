import { AxiosResponse, GenericAbortSignal } from 'axios';

import { ErrorResponse } from '../Scenarios/types/strategySettings';

import AxiosInstance from './axios';

export const getCommonErrorResponse = (error: any, hasData = false) => {
  const response: ErrorResponse = {
    isSuccess: false,
    status: error.response && error.response.status ? error.response.status : 503,
    message: error.response && error.response.data ? error.response.data.message : error.message ? error.message : '',
    data: hasData ? error.response.data : undefined,
  };
  return response;
};

export const getAPIResponse = (result: any) => {
  return {
    isSuccess: result.status === 200 || result.status === 210,
    status: result.status,
    ...result.data.data,
    message: result.message
      ? result.message
      : result.data && result.data.message
      ? result.data.message
      : result.data && result.data.data && result.data.data.message
      ? result.data.data.message
      : '',
  };
};

export const getAPIResponseInData = <T>(result: AxiosResponse & { message?: string }) => {
  return {
    isSuccess: true,
    status: result.status,
    data: result.data.data as T,
    message: result.message ? result.message : '',
  };
};

export const getMongoAPIResponseInData = <T = any>(result: any) => {
  return {
    isSuccess: result.status === 200 || result.status === 210,
    status: result.status,
    data: result.data as T,
  };
};

export const logApiError = (url: string, error: any, reqBody?: any) => {
  console.log('err in API =>', url);
  console.log('API parameters =>', reqBody);
  console.log('err response object =>', error.response);
  return;
};

export const getDateRangeQuery = (body: any) =>
  '&from_date=' + body.from_date + '&to_date=' + body.to_date + '&human_readable_date=' + body.human_readable_date;

export const getPagePageSizeQuery = (body: any) => '&page_number=' + body.page_number + '&page_size=' + body.page_size;

export const axiosGetWithRetries = async (url: string, abortSignal?: GenericAbortSignal) => {
  let inProgress = true;
  let apiResult: any;
  let delay = 1000;
  let timeSpent = 0;
  const multiplier = 2;
  const maxDelay = 16 * delay;
  const timeLimit = 5 * 60 * 1000;

  while (inProgress && timeSpent < timeLimit) {
    timeSpent += delay;

    const result = await AxiosInstance.get(url, { signal: abortSignal });

    if (result.status === 299) {
      delay = delay < maxDelay ? delay * multiplier : maxDelay;
      await new Promise((resolve) => setTimeout(resolve, delay));
    } else {
      inProgress = false;
      apiResult = result;
    }
  }

  return apiResult;
};
