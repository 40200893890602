import { AllAspectDetails } from '../../Aspects/types';

import { Anchor } from './anchor';
import { Note } from './note';

export const SET_ASPECT_DATA = 'SET_NOTES_AND_ANCHOR_OF_ASPECT';
export const UPDATE_ASPECT_DATA = 'UPDATE_NOTES_AND_ANCHOR_OF_ASPECT';
export const SET_ASPECT_NOTES = 'SET_ASPECT_NOTES';
export const SET_ALL_ORIGINAL_ASPECT_ITEMS = 'SET_ALL_ORIGINAL_ASPECT_ITEMS';

export interface UpdateAspectDetailsBody {
  data: Note | Anchor[];
  allAspectDetails: AllAspectDetails[];
  aspectId: string;
  noteId: string;
}

export interface GetAspectNotesBody {
  project_id: string;
  aspect_id: string;
}

export interface GetAspectResponse {
  isSuccess: boolean;
  data?: { notes: AspectNote[] };
  status: number;
}

export interface AspectNote {
  note_id: string;
  name: string;
}
