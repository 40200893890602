import { AspectLabels, AspectState, SET_ASPECTS_LABELS } from '../types';

type AspectLabelsAction = {
  type: typeof SET_ASPECTS_LABELS;
  data: AspectLabels;
};

type AspectAction = AspectLabelsAction;

const initialState: AspectState = {
  aspectLabels: undefined,
};

const reducer = (state = initialState, action: AspectAction): AspectState => {
  switch (action.type) {
    case SET_ASPECTS_LABELS:
      return { ...state, aspectLabels: action.data };
    default:
      return state;
  }
};

export default reducer;
